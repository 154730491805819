<template>
  <b-modal
    v-model="open"
    title="Configurações da Promoção"
    ok-title="Salvar"
    cancel-title="Fechar"
    size="xl"
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
    scrollable
    @cancel="close"
    @ok="save"
    @hide="() => {return false}"
  >
    <i2-json-editor v-model="model.settings" @input="(value) => model.settings = value" />
  </b-modal>
</template>
<script>

import I2JsonEditor from '@/components/i2/forms/elements/I2JsonEditor.vue'

export default {
  name: 'PromotionIntegrationConfigModal',
  components: {
    I2JsonEditor,
  },
  props: {
    isOpen: {
      required: true,
      type: Boolean,
    },
    uuidPromotion: {
      required: true,
      type: [String, null],
    },
  },
  data() {
    return {
      open: false,
      model: {
        settings: '',
      },
      jsonProperties: {
        mode: 'text',
        mainMenuBar: true,
        navigationBar: true,
      },
    }
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.open = true
        this.getConfiguration()
      }
    },
  },
  methods: {
    async getConfiguration() {
      if (!this.uuidPromotion) {
        return
      }
      const response = await this.$http.get(`promotions/${this.uuidPromotion}/load-settings`)
      if (response.settings) {
        this.model = response
      }
    },
    close() {
      this.$emit('onClose')
    },
    save(bvModal) {
      bvModal.preventDefault()

      console.log('this.model.settings ', this.model.settings)
      if (typeof this.model.settings !== 'string') {
        this.$notify.warning('Nenhuma alteração realizada.')
        return
      }
      this.$bvModal
        .msgBoxConfirm('Você confirma a alteração das configurações da promoção?', {
          title: 'Confirma a alteração?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async affirmative => {
          if (affirmative) {
            const response = await this.$http.post(`promotions/${this.uuidPromotion}/load-settings`, this.model)
            if (response.error) {
              this.$notify.error(response.error_message)
              return
            }
            this.$notify.success('JSON alterado com sucesso.')
            this.close()
          }
        })
    },
  },
}
</script>

<style scoped>

</style>
