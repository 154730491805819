import { render, staticRenderFns } from "./TabPromotionWeebHook.vue?vue&type=template&id=fd607c70&scoped=true&"
import script from "./TabPromotionWeebHook.vue?vue&type=script&lang=js&"
export * from "./TabPromotionWeebHook.vue?vue&type=script&lang=js&"
import style0 from "./TabPromotionWeebHook.vue?vue&type=style&index=0&id=fd607c70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd607c70",
  null
  
)

export default component.exports