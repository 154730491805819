<template>
  <JsonEditorVue
    v-model="inputValue"
    v-bind="jsonProperties"
    class="min-heigth-500"
  />
</template>
<script>
import JsonEditorVue from 'json-editor-vue'

export default {
  name: 'I2JsonEditor',
  components: { JsonEditorVue },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      inputValue: '',
      jsonProperties: {
        mode: 'text',
        mainMenuBar: true,
        navigationBar: true,
      },
    }
  },
  watch: {
    value(data) {
      this.inputValue = data
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  created() {
    this.inputValue = this.value
  },
}
</script>

<style scoped>
.min-heigth-500 {
  height: 500px;
}
</style>
