<template>
  <b-card>
    <page-header
      name="Personalizar Página"
    >
      <b-button
        variant="outline-primary"
        class="mr-1"
        @click="copyLink"
      >
        <FeatherIcon icon="CopyIcon" />
        &nbsp;<span class="text-nowrap">Copiar Link</span>
      </b-button>
      <b-button
        variant="primary"
        @click="save"
      >
        <FeatherIcon icon="SaveIcon" />
        &nbsp;<span class="text-nowrap">Salvar</span>
      </b-button>
    </page-header>
    <i2-divider />
    <b-card-body>
      <h5>Cores</h5>
      <b-row class="align-items-center">
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Botão:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.button.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Cabeçalho:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.header.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Rodapé:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.footer.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fundo de Conteúdo:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.content.background_color"
            class="input-color"
            type="color"
          />
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte de Botão:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.button.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte de Cabeçalho:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.header.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Fonte de Rodapé:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-input
            v-model="model.footer.text_color"
            class="input-color"
            type="color"
          />
        </b-col>
      </b-row>
      <i2-divider />
      <h5>Comportamento</h5>
      <b-row class="align-items-center">
        <b-col md="2" sm="2" class="text-nowrap">
          Mostrar Cabeçalho:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-checkbox
            v-model="model.header.show"
            name="model_header_show"
            switch
            inline
          />
        </b-col>
        <b-col md="2" sm="2" class="text-nowrap">
          Mostrar Rodapé:
        </b-col>
        <b-col md="1" sm="1">
          <b-form-checkbox
            v-model="model.footer.show"
            name="model_footer_show"
            switch
            inline
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import I2Divider from '@/components/i2/content/i2Divider.vue'

export default {
  name: 'TabVisualPromotion',
  components: { I2Divider },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      model: {
        header: {
          background_color: '',
          text_color: '',
          header: true,
        },
        footer: {
          background_color: '',
          text_color: '',
          show: true,
        },
        content: {
          background_color: '',
          text_color: '',
        },
        button: {
          background_color: '',
          text_color: '',
        },
      },
    }
  },
  async mounted() {
    this.model.footer.show = true
    this.model.header.show = true
    await this.getSettings()
  },
  methods: {
    async getSettings() {
      const response = await this.$http.get(`/promotions-external-settings/${this.$route.params.uuid}`)
      if (response && response.settings) {
        this.model = response.settings
      }
    },
    async save() {
      const response = await this.$http.post(`/promotions-external-settings/${this.promotion.uuid}`, this.model)
      if (response && response.erro_status) {
        this.$notify.error('Erro ao salvar as configurações.')
        return
      }
      this.$notify.success('Configurações salvas com sucesso.')
    },
    async copyLink() {
      const response = await this.$http.get(`/promotions-external-settings/${this.$route.params.uuid}/page`)
      const link = `${process.env.VUE_APP_URL}/public/participants?code=&${response}`
      navigator.clipboard.writeText(link)
    },
  },
}
</script>

<style scoped>
.input-color {
  width: 50px !important;
}
</style>
