<template>
  <div>
    <b-modal
      v-model="showModal"
      :title="title"
      cancel-title="Fechar"
      no-close-on-backdrop
      ok-title="Fechar"
      size="xl"
      scrollable
      ok-only
      @change="onHide"
    >
      <b-card>
        <b-form>
          <b-row align-v="center">
            <b-col
              sm="12"
              md="3"
            >
              <i2-form-vue-select
                v-model="search.status"
                :options="situationList"
                label="Situção"
                name="event"
                multiple
              />
            </b-col>
            <b-col
              sm="6"
              md="3"
            >
              <i2-form-input-date
                v-model="search.start_date"
                label="Data Inicial"

                name="start_date"
              />
            </b-col>
            <b-col
              sm="6"
              md="3"
            >
              <i2-form-input-date
                v-model="search.end_date"
                label="Data Final"
                name="end_date"
              />
            </b-col>
            <b-col align-self="center">
              <b-button block @click="getItems">
                <feather-icon icon="SearchIcon"/>
                Pesquisar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card>
        <Grid
          :fields="fields"
          :items="items"
          edit-button-label="edit"
          new-button-label="new"
          :show-search="false"
          @onChangePage="getItems"
        >
          <template #cell(date)="data">
            {{ data.item.date | moment('DD/MM/YYYY DD:mm:ss') }}
          </template>
          <template #cell(payload)="data">
            <div :id="'request_'+data.item.id" style="max-width: 100%" class="response-col text-truncate" @click="onShowDetail(data.item.payload)">
              {{ data.item.payload }}
            </div>

          </template>
          <template #cell(response)="data">
            <div :id="'response_'+data.item.id" class="response-col  text-truncate"
                 @click="onShowDetail(data.item.response)"
            >
              {{ data.item.response }}
            </div>

          </template>
          <template
            #cell(status)="data"
            style="width: 20%"
          >
            <b-badge v-if="data.item.success" variant="success" block>
              ({{ data.item.status_code }}) - Sucesso
            </b-badge>
            <b-badge v-if="!data.item.success" variant="danger" block>
              ({{ data.item.status_code }}) - Error
            </b-badge>
          </template>
        </Grid>
      </b-card>
    </b-modal>
    <b-modal v-model="showDetail"
             centered
             hide-backdrop
             ok-only
             ok-title="fechar"
             hide-header-close
             @hide="onCloseDetail"
    >
      <prism language="json" :plugins="['normalize-whitespace', 'line-numbers']">
        {{ currentDetail }}
      </prism>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import toIsoDate from '@/mixins/toIsoDate'
import { VBPopover } from 'bootstrap-vue'
import Prism from 'vue-prism-component'
import 'prismjs/components/prism-json'

export default {
  name: 'WebhookLogList',
  components: {
    Prism,
  },
  directives: {
    'b-popover': VBPopover,
  },
  mixins: [
    toIsoDate,
  ],
  props: {
    value: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      search: {
        status: [],
      },
      items: [],
      situationList: [],
      showModal: false,
      showDetail: false,
      currentDetail: '',
      title: 'Eventos',
      fields: [
        {
          key: 'date',
          label: 'data',
          class: 'w-15',
          thClass: 'text-center',
        },
        {
          key: 'url',
          label: 'Url',
          class: 'w-20',
        },
        {
          key: 'payload',
          label: 'Requisição',
          thClass: 'w-20',
          tdClass: '',
        },
        {
          key: 'response',
          label: 'Retorno',
          class: 'response-col',

        },
        {
          key: 'status',
          label: 'Status',
          class: 'w-20',
        },
      ],
    }
  },
  watch: {
    value(val) {
      this.showModal = val !== null
      if (this.showModal) {
        this.getItems(null)
      }
    },
  },
  async mounted() {
    this.situationList = [
      {
        text: 'Com Sucesso',
        value: 'success',
      },
      {
        text: 'Com Erro',
        value: 'error',
      },
    ]
    this.getItems()
  },
  methods: {
    ...mapActions('webhookTopics', ['getTopics']),
    onShowDetail(item) {
      this.showDetail = true
      this.currentDetail = item
    },
    onCloseDetail() {
      this.showDetail = false
      this.currentDetail = ''
    },
    onHide() {
      this.showModal = false
      this.$emit('onclose', true)
    },
    async getItems(page) {
      const search = { ...this.search }
      if (search.start_date) {
        search.start_date = this.toIsoDate(search.start_date)
      }
      if (search.end_date) {
        search.end_date = this.toIsoDate(search.end_date)
      }
      search.status = search.status.map(item => item.value)
      this.items = await this.$search.get(
        `/webhooks/events/${this.value}/logs`,
        page,
        search,
        '',
      )
    },
  },
}
</script>

<style>
.response-col {
    width: 200px;
}
</style>
