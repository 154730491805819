<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="text-right">

          <b-button
            variant="outline-primary"
            class="mr-50"
            @click="toggleImportCompanies"
          >
            <feather-icon
              icon="FileIcon"
              class="mr-50"
            />
            <span class="align-middle">Importar</span>
          </b-button>

          <b-button
            variant="primary"
            @click="toggleCompany"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">Adicionar</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <Grid
            :fields="fields"
            :items="items"
            edit-button-label="edit"
            new-button-label="new"
            :show-search="false"
            @onChangePage="loadCompanies"
          >
            <template v-slot:search>
              <b-row class="p-0 pl-1">
                <b-col
                  md="10"
                  class="p-0 pr-1"
                >
                  <b-form-input
                    v-model="search.search"
                    class="filter-input"
                    placeholder="Pesquisar por Nome, email ou cpf"
                    name="nome"
                    @keyup.enter="loadCompanies"
                  />
                </b-col>
                <b-col
                  sm="12"
                  md="2"
                >
                  <b-button
                    variant="secondary"
                    type="button"
                    @click="loadCompanies"
                  >
                    <FeatherIcon icon="SearchIcon" />
                    <span
                      class="text-nowrap"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </template>
            <template #cell(document)="data">
              <the-mask class="document-table" readonly disabled="true" :mask="['###.###.###-##', '##.###.###/####-##']" :value="data.item.document" />
            </template>

            <template #cell(action)="data">
              <b-button
                variant="danger"
                class="btn-icon"
                @click="remove(data.item)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </template>
          </Grid>
        </b-col>
      </b-row>
    </b-card>

    <b-sidebar
      id="add-company-promotion"
      ref="sideBar"
      :visible="isActive"
      backdrop
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      bg-variant="white"
      right
      shadow
      sidebar-class="sidebar-lg"
      header-class="content-sidebar-header"
      footer-class="content-sidebar-footer"
      @change="(val) => $emit('update:is-active', val)"
    >
      <template #header >
        <div class="d-flex justify-content-between align-items-center w-100" >
          <h5 class="mb-0">
            Cadastro de Empresa
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer float-right"
            icon="XIcon"
            size="16"
            @click="toggleCompany"
          />
        </div>
      </template>

      <template #default>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSave)"
          >
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-input
                  v-model="model.name"
                  rules="required"
                  label="Empresa"
                  placeholder="Digite o nome da empresa"
                  name="name"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-input
                  v-model="model.company_name"
                  rules="required"
                  label="Nome fantasia"
                  placeholder="Digite o nome fantasia da empresa"
                  name="company_name"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-input
                  v-model="model.document"
                  rules="required"
                  label="Cpf/Cnpj"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                  placeholder=""
                  name="name"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-input
                  v-model="model.phone"
                  label="Telefone"
                  :mask="['(##) #####-####','(##) ####-####']"
                  placeholder=""
                  name="phone"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>

      <template #footer >
        <div class="p-1" >
          <b-row>
            <b-col
              sm="12"
              md="12"
              class="text-right"
            >
              <b-button
                variant="primary"
                type="button"
                class="mr-2"
                @click="onSave"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Salvar
              </b-button>
              <b-button
                variant="outline-danger"
                type="button"
                @click="onCancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-sidebar>

    <b-modal
      v-model="showImportCompanies"
      title="Selecione um arquivo"
      ok-title="Salvar"
      cancel-title="Fechar"
      no-close-on-backdrop
      @ok="onImportFile"
    >
      <validation-observer
        ref="refFormObserverFile"
      >
        <b-card-text>
          Selecione o arquivo com as empresas participantes que deseja importar.<br>
          Baixe <a :href="urlTemplateFile" download="template_importacao_empresas.csv">aqui</a> o template do arquivo.<br>
        </b-card-text>
        <b-row>
          <b-col>

            <i2-form-input-file
              v-model="companiesFile"
              required
              name="file"
              rules="required"
              label="Arquivo"
              placeholder="Informe aqui um arquivo."
              drop-placeholder="Arraste um arquivo para cá"
              browse-text="Procurar"
              accept="text/csv"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <i2-form-input v-model="separador" rules="required" name="separador" maxlength="1"
                           label="Separador de campo"
            />
          </b-col>
        </b-row>

      </validation-observer>
    </b-modal>
  </div>
</template>

<script>

import { ValidationObserver } from 'vee-validate'
import I2FormInputFile from '@/components/i2/forms/elements/I2FormInputFile.vue'

export default {
  name: 'TabCompanyAvailable',
  components: {
    I2FormInputFile,
    ValidationObserver,
  },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: {
        search: '',
      },
      isActive: false,
      showImportCompanies: false,
      model: {
        name: '',
        document: '',
        company_name: '',
        phone: '',
      },
      companiesFile: null,
      separador: ';',
      urlTemplateFile: '',
      items: [],
      fields: [
        {
          key: 'name',
          label: 'Razão Social',
          class: 'w-30',
          sortable: true,
        },
        {
          key: 'company_name',
          label: 'Nome Fantasia',
          class: 'w-20',
          sortable: true,
        },
        {
          key: 'document',
          label: 'Documento',
          class: 'w-10',
          sortable: true,
        },
        {
          key: 'phone',
          label: 'Telefone',
          class: 'w-15',
        },
        {
          key: 'action',
          label: 'Ação',
          class: 'w-10',
        },
      ],
    }
  },
  watch: {
    promotion() {
      if (this.promotion && this.promotion.uuid) {
        this.loadCompanies()
      }
    },
  },
  mounted() {
    const urlData = new URL(process.env.VUE_APP_URL_API)
    let url = `${urlData.protocol}//${urlData.host}`

    this.urlTemplateFile = `${url}/files/template_importacao_empresas.csv`
  },
  methods: {
    toggleCompany() {
      this.isActive = !this.isActive
    },
    onCancel() {
      this.resetModel()
      this.toggleCompany()
    },
    resetModel() {
      this.model = {
        name: '',
        document: '',
        company_name: '',
        phone: '',
      }
    },
    async onSave() {
      let isValid = await this.$refs.refFormObserver.validate()
      if (!isValid) {
        this.$notify.error('Verifique os campos em vermelho.')
        return
      }
      await this.$http.post(`/promotions/${this.promotion.uuid}/company-available`, this.model).then(response => {
        if (response && response.error_message) {
          this.$notify.error(response.error_message)
        }

        if (response && response.message) {
          this.$notify.success(response.message)
          this.resetModel()
          this.$refs.refFormObserver.reset()
          this.loadCompanies()
        }
      })
    },
    async remove(company) {
      await this.$http.delete(`/promotions/${this.promotion.uuid}/company-available/${company.uuid}`).then(() => {
        this.loadCompanies()
        this.$notify.success('Empresa participante excluída com sucesso da promoção.')
      })
    },
    async loadCompanies(page) {
      this.items = await this.$search.get(
        `/promotions/${this.promotion.uuid}/company-available`,
        page,
        this.search,
        '',
      )
    },
    toggleImportCompanies() {
      this.showImportCompanies = !this.showImportCompanies
    },
    async onImportFile(event) {
      event.preventDefault()
      const success = await this.$refs.refFormObserverFile.validate()
      if (!success) {
        return
      }
      const formData = new FormData()
      formData.append('file', this.companiesFile)
      formData.set('field_separator', this.separador)
      const response = await this.$http.post(`/promotions/${this.promotion.uuid}/import-companies`, formData)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      this.$notify.success('Empresas Importadas com sucesso.')
      this.toggleImportCompanies()
      this.loadCompanies()
    },
  },
}
</script>

<style scoped>
.document-table {
  border: none;
  background-color: transparent;
  color: #6e6b7b;
}
</style>
