const AccountabilityTypes = [
  {
    value: 'eve_draw',
    text: 'Na véspera do sorteio',
  },
  {
    value: 'day_draw',
    text: 'No dia do sorteio',
  },
]
export default AccountabilityTypes
