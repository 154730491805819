import moment from 'moment-timezone'

const toIsoDate = {
  methods: {
    toIsoDate(date) {
      let format = 'DD/MM/YYYY'
      if (date.indexOf('-') > 0) {
        format = 'YYYY-MM-DD'
      }
      return moment(date, format).format('YYYY-MM-DD')
    },
  },
}
export default toIsoDate
