<template>
  <b-container>
    <b-card>
      <b-row class="border-bottom">
        <b-col>
          <b-card-header>
            <b-card-title>Eventos</b-card-title>
          </b-card-header>
        </b-col>
        <b-col class="text-right">
          <b-button variant="primary" @click="newWebhook()">
            <feather-icon icon="PlusIcon"/>
            Novo
          </b-button>
        </b-col>
      </b-row>
      <b-card-body>
        <b-list-group>
          <b-list-group-item v-for="(action, i) of actions"
                             :key="i"
          >
            <b-row>

              <b-col>
                {{ action.name }}<br>
                <span class="text-muted"> ({{ action.topic }})</span>
                <br>

              </b-col>
              <b-col>
                {{ action.url }}
              </b-col>
              <b-col class="text-center">
                {{ action.secret }}
              </b-col>
              <b-col class="text-right">
                <feather-icon v-if="action.active" icon="CheckCircleIcon" style="color:green"/>
                <feather-icon v-if="!action.active" icon="CloudOffIcon" style="color:red"/>
                <i2-icon-button icon="SettingsIcon" variant="outline-primary" class="ml-1"
                                @click="toggleWebhook(action)"
                />
                <i2-icon-button
                  fa-icon="fa-trash"
                  variant="outline-danger"
                  class="ml-1"
                  @click="onDelete(action)"
                />
                <i2-icon-button
                  icon="FileTextIcon"
                  variant="outline-secondary"
                  class="ml-1"
                  @click="onOpenLogs(action)"
                />

              </b-col>
            </b-row>

          </b-list-group-item>

        </b-list-group>
        <b-row v-if="actions.length === 0" class="text-center">
          <b-col>
            <b-img :src="require('@/assets/images/icons/empty.svg')" height="30" />
            Nenhum registro encontrado.<br>
            <b-button variant="outline-primary" @click="newWebhook()">
              <feather-icon icon="PlusIcon"/>
              Adicionar Webhooks
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-sidebar
      id="add-company-promotion"
      ref="sideBar"
      :visible="isActive"
      backdrop
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      bg-variant="white"
      right
      shadow
      width="500px"
      sidebar-class="sidebar-lg"
      header-class="content-sidebar-header"
      footer-class="content-sidebar-footer"
      @change="(val) => $emit('update:is-active', val)"
    >
      <template #header>
        <div class="d-flex justify-content-between align-items-center w-100">
          <h5 class="mb-0">
            WebHooks
          </h5>
          <feather-icon
            class="ml-1 cursor-pointer float-right"
            icon="XIcon"
            size="16"
            @click="onCancel"
          />
        </div>
      </template>

      <template #default>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSave)"
          >
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-vue-select
                  v-model="model.topic"
                  rules="required"
                  :options="topicList"
                  label="Tópicos"
                  name="event"
                  @onChange="setEventDescription"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-input
                  v-model="model.name"
                  rules="required"
                  label="Nome do Webhook"
                  placeholder="Digite o nome do webhook"
                  name="name"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <i2-form-input
                  v-model="model.url"
                  rules="required|url"
                  type="url"
                  label="Url do Webhook"
                  placeholder="Url do Webhook"
                  name="url"
                />
              </b-col>
            </b-row>
            <b-row align-v="center">
              <b-col
                sm="8"
                md="10"
              >
                <i2-form-input
                  v-model="model.secret"
                  rules="required"
                  label="Secret"
                  placeholder="Secret do webook"
                  name="secret"
                />
              </b-col>
              <b-col>
                <i2-icon-button
                  icon="RepeatIcon"
                  title="Gerar Secret"
                  variant="secondary"
                  @click="makeSecret"
                /></b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="6"
              >
                <i2-form-input
                  v-model="model.timeout"
                  rules="required"
                  label="Timeout"
                  placeholder="timeout da conexão"
                  name="timeout"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
              >
                <i2-form-input
                  v-model="model.max_tries"
                  rules="required"
                  label="Número de Tentativas"
                  placeholder="Número máximo de tentativas"
                  name="max_tries"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col
                sm="12"
                md="12"
              >
                <b-form-checkbox
                  v-model="model.active"
                  checked="true"
                  name="check-button"
                  switch
                >
                  Ativo
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col v-if="model.topic && model.topic.payload !== ''" >
                <prism language="javascript" :code="model.topic.payload || '{}'"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </template>

      <template #footer>
        <div class="p-1">
          <b-row>
            <b-col
              sm="12"
              md="12"
              class="text-right"
            >
              <b-button
                variant="primary"
                type="button"
                class="mr-2"
                @click="onSave"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Salvar
              </b-button>
              <b-button
                variant="outline-warning"
                type="button"
                class="mr-2"
                @click="onTestConfig"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Testar
              </b-button>
              <b-button
                variant="outline-danger"
                type="button"
                @click="onCancel"
              >
                Cancelar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-sidebar>
    <webhook-log-list v-model="logItem" @onClose="onCloseLogs"/>
  </b-container>
</template>
<script>
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { mapActions } from 'vuex'
import Prism from 'vue-prism-component'
import WebhookLogList from '@/views/promotions/WebhookLogList.vue'

export default {
  name: 'TabPromotionWeebHook',
  components: {
    WebhookLogList,
    Prism,
    BListGroup,
    BListGroupItem,
    ValidationObserver,
  },
  data() {
    return {
      isActive: false,
      webookActive: null,
      actions: [],
      topicList: [],
      isUpdate: false,
      logItem: null,
      model: {
        name: '',
        url: '',
        secret: '',
        active: true,
        timeout: 60,
        max_tries: 3,
        topic: null,
      },
    }
  },

  async mounted() {
    let topics = await this.getTopics()
    this.topicList = topics.map(item => ({
      value: item.name,
      text: `${item.description}  - (${item.name})`,
      description: item.description,
      payload: item.payload_example,
    }))
    this.getItems()
  },
  methods: {
    ...mapActions('webhookTopics', ['getTopics']),
    ...mapActions('webhookEvents', ['saveEvent', 'getEvents', 'deleteEvent']),
    async getItems() {
      this.actions = await this.getEvents(this.$route.params.uuid)
    },
    toggleWebhook(item) {
      this.webookActive = item.active
      this.model = { ...item }
      this.model.topic = this.topicList.find(topic => topic.value === item.topic)
      this.isActive = true
    },
    newWebhook() {
      this.isActive = true
    },
    setEventDescription() {
      if (this.model.topic) {
        this.model.name = this.model.topic.description || ''
      }
    },
    onCancel() {
      this.resetModel()
      this.isActive = false
    },
    resetModel() {
      this.model = {
        name: '',
        url: '',
        secret: '',
        active: true,
        timeout: 60,
        max_tries: 3,
        topic: null,
      }
    },
    async onSave() {
      let isValid = await this.$refs.refFormObserver.validate()
      if (!isValid) {
        this.$notify.error('Verifique os campos marcados em vermelho.')
        return
      }

      const data = { ...this.model }
      data.promotion = this.$route.params.uuid
      data.topic = this.model.topic.value
      let response = await this.saveEvent(data)
      if (response.error) {
        this.$notify.error(response.error_message)
        return
      }
      let message = 'Webhook criado com sucesso!'
      this.$notify.success(message)
      this.$refs.refFormObserver.reset()
      this.getItems()
      if (data.uuid) {
        this.isActive = false
      }
      this.resetModel()
    },
    makeSecret() {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      let counter = 0
      while (counter < 16) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
      }
      this.model.secret = result
      return result
    },
    async onDelete(model) {
      let retorno = await this.deleteEvent(model)
      if (retorno) {
        await this.getItems()
      }
    },

    async onTestConfig() {
      let isValid = await this.$refs.refFormObserver.validate()
      if (!isValid) {
        this.$notify.error('Verifique os campos marcados em vermelho.')
        return
      }

      const data = { ...this.model }
      data.promotion = this.$route.params.uuid
      data.topic = this.model.topic.value
      const response = await this.$http.post('/webhooks/events/test', data)
      if (!response?.success) {
        this.$notify.error(response.message || 'falha ao executar o webhook')
        return
      }
      this.$notify.success('webhook executado com sucesso!')
    },
    onOpenLogs(model) {
      this.logItem = model.uuid
    },
    onCloseLogs() {
      this.logItem = null
    },
  },
}
</script>

<style scoped>
.vs__selected-options {
    flex-wrap: nowrap;
    max-width: calc(100% - 40px); /* change this to `- 40px` if you're supporting a `clearable` field; I was not */
}

.vs__selected {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}
</style>
